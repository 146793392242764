<template>
  <v-footer
    app
    class="grey--text text-caption"
    absolute
  >
    <div>
      {{ $t('app.title') }}
      <small
        v-if="showSimple"
        @click="showSimple=!showSimple"
      >v{{ appVersion }}</small>
      <small
        v-else
        @click="showSimple=!showSimple"
      >Environment: {{ appEnvironment }}, f{{ appVersion }}, b{{ user.backendVersion }}, d{{ user.dbVersion }} </small>
    </div>
    <div
      v-if="appEnvironment !== 'production'"
      class="environmentNameStyle"
    >
      {{ appEnvironment }}  - KUN TEST DATA!
    </div>
    <v-spacer />
    <div>
      TPA Solutions | +45 4499 9998 | support@tpa-solutions.dk | Powered by IPA innovation
    </div>
  </v-footer>
</template>

<script>
import config from '@/assets/config.json'

export default {
  data() {
    return {
      showSimple: true
    }
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    appVersion() {
      return this.$store.getters.appVersion;
    },
    appEnvironment() {
      return config.environment;
    }
  }
}
</script>