<template>
  <v-snackbar
    v-model="show"
    :color="color"
    bottom
    transition="slide-y-reverse-transition"
    :timeout="timeout"
  >
    <div class="d-flex align-center">
      <v-icon v-if="icon" left> {{ icon }} </v-icon>
      <span> {{ message }} </span>
    </div>

    <template v-slot:action="{ attrs }">
      <v-btn
        v-if="url"
        v-bind="attrs"
        text
        @click="onClick"
      >
        Gå til
      </v-btn>
      <v-btn
        v-bind="attrs"
        text
        @click="show = false"
      >
        {{ $t('close') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Notifier',
  data() {
    return {
      show: false,
      message: '',
      color: '',
      icon: '',
      url: '',
      timeout: 5000
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'notifier/showMessage') {
        this.message = state.notifier.message;
        this.color = state.notifier.color;
        this.icon = state.notifier.icon;
        this.url = state.notifier.url;
        this.timeout = state.notifier.timeout;
        this.show = true;
      }
    });
  },
  methods: {
    onClick() {
      this.show = false;
      window.open(this.url, '_blank');
    }
  }
}
</script>