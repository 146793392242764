import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import da from 'vuetify/lib/locale/da'

Vue.use(Vuetify)

const opts = {}

// Farver	RGB	        HEX	    
// Grå 	    84 83 82	#545352	
// lysgrå	119 119 119	#777777	    
// Sort	    0 0 0 	    #000	
// Hvid	    255 255 255 #fff	
// Orange 	225 181 55 	#edbf3a	
// purple: '#6032A1',
// teal:'#2C6B98',

function setInitTheme() {
  const prefersDark = localStorage.getItem('prefers-dark');
  if (prefersDark !== null && prefersDark !== 'undefined') {
    return JSON.parse(prefersDark);
  } else {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

}

const vuetify = new Vuetify({
  lang: {
    locales: { da },
    current: 'da'
  },
  theme: {
    options: {
      customProperties: true
    },
    dark: setInitTheme(),
    themes: {
      light: {
        primary: '#545352',
        secondary: '#777777',
        accent: '#edbf3a',
        error: '#f44336',
        warning: '#ff9800',
        info: '#2196f3',
        success: '#4caf50',
        background: '#FAFAFA', 
        purple: '#6032A1',
        teal: '#2C6B98',
      },
      dark: {
        primary: '#545352',
        secondary: '#777777',
        // background: '#000000'
      }
    },
  }
})

export default new Vuetify(vuetify)