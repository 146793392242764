<template>
  <v-app>
    <router-view />

    <!-- <AcceptTerms v-if="!$store.getters.userHasAcceptedTerms" /> -->
    <UpdateNotification
      text="En opdatering er tilgængelig"
      button-text="Opdater"
    />
    <Footer />
    <Notifier />
  </v-app>
</template>

<script>
import Notifier from '@/components/Notifier.vue'
import Footer from '@/components/Footer.vue'
import AcceptTerms from '@/components/AcceptTerms'

export default {
  name: 'App',
  components: {
    Notifier,
    Footer,
    AcceptTerms
  },
  created: async function() {
    this.$store.commit('setLoading', true);

    // Load user
    this.$store.dispatch('loadUser');
  }
}
</script>

<style>
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app .v-application [class*='text-'] { font-family: Inter !important; }

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.v-autocomplete .v-chip.v-size--small {
  border-radius: 4px;
}

</style>