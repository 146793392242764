import QueryString from "qs";

const actions = {
  getMessages: async function({}, relationIds) {
    console.log('Getting messages for relations:', relationIds);
    this.loadingMessages = true;

    try {
      const response = await this._vm.$AuthService.api.get('messages', {
        params: {
          relationIds
        },
        paramsSerializer: params => {
          return QueryString.stringify(params);
        }
      });

      if (!response.data.success) throw 'Error loading messages';

      return response.data.model;
    } catch (error) {
      console.error(error);
      this.$notifier.showError({ message: 'Beskeder blev ikke indlæst' });
    } finally {
      this.loadingMessages = false;
    }
  },
}

export default {
  namespaced: true,
  actions
}