import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

Vue.use(VueI18n)

export const i18n = new VueI18n({})

const loadedLanguages = []

function _setI18nLanguage(lang) {
    i18n.locale = lang
    axios.defaults.headers.common['Accept-Language'] = lang
    document.querySelector('html').setAttribute('lang', lang)
    return lang
}

export async function setI18nLanguage(lang) {
    // If the same language
    if (i18n.locale === lang) {
        return Promise.resolve(_setI18nLanguage(lang))
    }

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(_setI18nLanguage(lang))
    }

    // If the language hasn't been loaded yet
    const response = await axios.get(`assets/lang/${lang}.json`)
    i18n.setLocaleMessage(lang, response.data)
    loadedLanguages.push(lang)
    return _setI18nLanguage(lang)
}
