import Vue from 'vue'
import App from './App.vue'
import router from './router'
import moment from 'moment'
import config from '@/assets/config.json'
import './filters';
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import notifierPlugin from './plugins/notifier';
import AuthService from '@/services/AuthService'
import { i18n, setI18nLanguage } from './services/i18n-setup'
import VueChartJs from 'vue-chartjs'
import "@/assets/global.css" // Importing the global css file
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import DateTimePicker from 'vuetify-datetime-picker'
import Updater from '@ipainnovation/vue-updater'
import vMultiselectListbox from 'vue-multiselect-listbox';
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
import VCurrencyField from 'v-currency-field';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import './registerServiceWorker'
import DatePick from './components/DatePick.vue'

Vue.config.productionTip = false

/**
 * Sentry setup
 */
Sentry.init({
  Vue,
  dsn: "https://085bb666c87545b69440614fffa161b9@o876503.ingest.sentry.io/6061083",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', /api-.*tpa-solutions.dk/]
    }),
  ],
  enabled: config.environment === 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: 'leveranceportalen@' + process.env.PACKAGE_VERSION ?? 'no-version',
  environment: config.environment ?? 'Unknown environment'
});

Vue.component('vMultiselectListbox', vMultiselectListbox)
Vue.component('tpa-datepicker', DatePick)

Vue.use(VueTelInput)
Vue.use(DateTimePicker)
Vue.use(Updater)
Vue.use(VCurrencyField, {
  allowNegative: false,
  decimalLength: 0,
  valueAsInterger: true
})
Vue.use(VueChartJs);
Vue.use(notifierPlugin, { store });

Vue.prototype.moment = moment
Vue.prototype.Sentry = Sentry
Vue.prototype.$AuthService = new AuthService(store)

export default new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

setI18nLanguage('da')
