import Vue from 'vue'
import Vuex from 'vuex'
import notifier from './modules/notifier'
import specification from './modules/specification'
import messages from './modules/messages'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    notifier,
    messages,
    specification
  },
  state: {
    user: null,
    primaryCustomerId: localStorage.getItem('tpa:primary-customer-id') ?? null,
    packageVersion: null,
    loading: false,
    loadingToggleShowOnlyRelevantContent: false
  },
  mutations: {
    setUser (state, payload) {
      state.packageVersion = process.env.PACKAGE_VERSION || '0',
      state.user = payload;
      console.log('User has been committed')
    },
    setPrimaryCustomerId (state, payload) {
      state.primaryCustomerId = payload
      localStorage.setItem('tpa:primary-customer-id', payload)
      this._vm.$AuthService.setCustomerId(payload)
    },
    setLoading (state, value) {
      state.loading = value;
    },
    setOnlyShowRelevantContent (state, value) {
      state.user.showOnlyRelevantContent = value;
    }
  },
  getters: {
    appVersion: state => state.packageVersion,
    currentUser: state => state.user,
    currentCustomer: state => state.primaryCustomerId ? state.user?.customers?.find(x => x.id === state.primaryCustomerId) : null,
    userHasMultipleCustomers: state => state.user?.customers?.length > 1,
    userId: state => state.user.id,
    userOrganizationName: (state) => {
      return state.user.organizationTitle;
    },
    userHasAcceptedTerms: (state) => !!state.user.termsAcceptedOnDate,
    showOnlyRelevantContent: state => {
      return state.user.showOnlyRelevantContent;
    },
    isInternal: state => state.user?.organizationKind === 'Internal'
  },
  actions: {
    async loadUser ({ commit }) {
      try {
        await this._vm.$AuthService.init();

        const user = await this._vm.$AuthService.api.get('user');

        if (!user) {
          throw 'User failed to load';
        }

        if (user.data.customers?.length === 1) {
          commit('setPrimaryCustomerId', user.data.customers[0].id)
        }

        commit('setUser', user.data);
      } catch (error) {
        console.error(error);
      } finally {
        commit('setLoading', false);
      }
    },
    async toggleShowOnlyRelevantContent ({ commit, state, getters }) {
      state.loadingToggleShowOnlyRelevantContent = true;

      try {
        await this._vm.$AuthService.api.patch(`user/edit/onlyshowrelevantcontent?show=${!getters.showOnlyRelevantContent}`);
        commit('setOnlyShowRelevantContent', !getters.showOnlyRelevantContent);
      } catch (error) {
        console.error(error);
      } finally {
        state.loadingToggleShowOnlyRelevantContent = false;
      }
    },
    logError(context, { level, transaction, message, error = null }) {
      try {
        this._vm.Sentry.withScope((scope) => {
          console.log(typeof level, typeof transaction, message, error);
          if (level) {
            scope.setLevel(level);
          }

          if (transaction) {
            scope.setTransactionName(transaction);
          }

          if (message) {
            this._vm.Sentry.captureException(new Error(message));
          } else if (error) {
            this._vm.Sentry.captureException(error)
          } else {
            this._vm.Sentry.captureException();
          }
        });
      } catch (err) {
        console.error(err);
      }
    },
  }
})

export default store;
