export const state = () => ({
  message: '',
  color: '',
  icon: '',
  url: ''
})

export const mutations = {
  showMessage (state, payload) {
    state.message = payload.message;
    state.color = payload.color;
    state.icon = payload.icon;
    state.url = payload.url;
  }
}

export default {
  namespaced: true,
  state,
  mutations
}