import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    // beforeEnter: (to, from, next) => {
    //   store.commit('setLoading', true)

    //   console.log('MainLayout:beforeEnter')
    //   console.log('from', from)
    //   console.log('to', to)

    //   setTimeout(() => {
    //     console.log('continuing..')
    //     store.commit('setLoading', false)
    //     next()
    //   }, 2000);
    // },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('../views/Dashboard.vue')
      },
      {
        path: 'supportticket',
        name: 'support-ticket-list',
        component: () => import('../views/SupportTicketList.vue')
      },
      {
        path: 'supportticket/:id',
        name: 'support-ticket-detail',
        component: () => import('../views/SupportTicketDetail.vue')
      },
      {
        path: 'taskrequest',
        name: 'task-request-list',
        component: () => import('../views/TaskRequestList.vue')
      },
      {
        path: 'taskrequest/:id',
        name: 'task-request-detail',
        component: () => import('../views/TaskRequestDetail.vue')
      },
      {
        path: 'delivery',
        name: 'delivery-list',
        component: () => import('../views/DeliveryList.vue')
      },
      {
        path: 'delivery/:id',
        name: 'delivery-detail',
        component: () => import('../views/DeliveryDetail.vue')
      },
      {
        path: 'location',
        name: 'locations',
        component: () => import('../views/Locations.vue')
      },
      {
        path: 'organization',
        name: 'organization',
        component: () => import('../views/Organization.vue')
      },  
      {
        path: 'part',
        name: 'part-list-customer',
        component: () => import('../views/PartListCustomer.vue')
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('../views/Reports.vue')
      },
      {
        path: 'help',
        name: 'help',
        component: () => import('../views/User/Help.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('../views/User/Terms.vue')
      },
      {
        path: 'account',
        name: 'account',
        component: () => import('../views/User/Account.vue')
      },
      {
        path: 'logoff',
        name: 'logoff',
        component: () => import('../views/User/Logoff.vue')
      },
      {
        path: '*/:relationId/specification/:specId',
        component: () => import('../views/Specification/Specification.vue'),
        children: [
          {
            path: '',
            name: 'spec-dashboard',
            component: () => import('../views/Specification/SpecificationDashboard.vue')
          },
          {
            path: 'resources',
            name: 'spec-resources',
            component: () => import('../views/Specification/SpecificationResources.vue')
          },
          {
            path: 'travel',
            name: 'spec-travel',
            component: () => import('../views/Specification/SpecificationTravel.vue')
          },
          {
            path: 'section/:sectionNumber',
            name: 'spec-section',
            component: () => import('../views/Specification/SpecificationSection.vue')
          },
          {
            path: 'group/:groupNumber',
            name: 'spec-group',
            component: () => import('../views/Specification/SpecificationGroup.vue')
          },
          {
            path: 'group/:groupNumber/section/:sectionNumber',
            name: 'spec-group-section',
            component: () => import('../views/Specification/SpecificationSection.vue')
          }
        ]
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/About.vue')
      },
      {
        path: 'viden/retningslinjer',
        name: 'guidelines',
        component: () => import('../views/Guidelines.vue')
      },
      {
        path: 'viden/vejledninger',
        name: 'instructions',
        component: () => import('../views/Instructions.vue')
      },
      {
        path: 'faq',
        name: 'faq',
        component: () => import('../views/FAQ.vue')
      },
      {
        path: 'parts',
        name: 'part-list-admin',
        component: () => import('../views/Admin/PartList.vue')
      },
      {
        path: 'parts/price-report',
        name: 'price-report',
        component: () => import('../views/Admin/PriceReport.vue')
      },
      {
        path: 'parts/price-pending',
        name: 'price-pending',
        component: () => import('../views/Admin/PricePending.vue')
      },
      {
        path: 'parts/create',
        name: 'part-create',
        component: () => import('../views/Admin/PartUpsert.vue')
      },
      {
        path: 'parts/supplier-orders',
        name: 'supplier-order-list',
        component: () => import('../views/Admin/SupplierOrders.vue')
      },
      {
        path: 'parts/supplier-orders/:id',
        name: 'supplier-order-edit',
        component: () => import('../views/Admin/SupplierOrderEdit.vue')
      },
      {
        path: 'parts/suppliers',
        name: 'supplier-list',
        component: () => import('../views/Admin/Suppliers.vue')
      },
      {
        path: 'parts/suppliers/:id',
        name: 'supplier-edit',
        component: () => import('../views/Admin/SupplierUpsert.vue')
      },
      {
        path: 'parts/manufacturers',
        name: 'manufacturer-list',
        component: () => import('../views/Admin/Manufacturers.vue')
      },
      // {
      //   path: 'admin/manufacturers/:id',
      //   name: 'manufacturer-edit',
      //   component: () => import('../views/Admin/ManufacturerUpsert.vue')
      // },
      {
        path: 'parts/tpa-categories',
        name: 'part-category-list',
        component: () => import('../views/Admin/PartCategoryList.vue')
      },
      {
        path: 'parts/customer-categories',
        name: 'customer-part-category-list',
        component: () => import('../views/Admin/CustomerPartCategoryList.vue')
      },
      {
        path: 'parts/update-prices',
        name: 'update-prices',
        component: () => import('../views/Admin/UpdatePartPrices.vue')
      },
      {
        path: 'parts/:id',
        name: 'part-detail',
        component: () => import('../views/Admin/PartDetail.vue')
      },
      {
        path: 'parts/:id/edit',
        name: 'part-edit',
        component: () => import('../views/Admin/PartUpsert.vue')
      },
      {
        path: 'admin/personrole',
        name: 'person-role',
        component: () => import('../views/Admin/PersonRole.vue')
      },
      {
        path: 'admin/user-edit/:id',
        name: 'user-edit',
        component: () => import('../views/Admin/UserEdit.vue')
      },
      {
        path: 'admin/user-create',
        name: 'user-create',
        component: () => import('../views/Admin/UserCreate.vue')
      },
      {
        path: 'admin/responsibilityrole',
        name: 'responsibility-role',
        component: () => import('../views/Admin/ResponsibilityRole.vue')
      },
      {
        path: 'admin/salary-basis',
        name: 'salary-basis',
        component: () => import('../views/Admin/SalaryBasis.vue')
      },
      {
        path: 'admin/invoice-draft',
        name: 'invoice-draft',
        component: () => import('../views/Admin/InvoiceDraft.vue')
      },
      {
        path: 'admin/system-log',
        name: 'system-log',
        component: () => import('../views/Admin/SystemLog.vue')
      }
    ]
  },
  {
    path: '/error',
    component: () => import('../layouts/ErrorLayout.vue'),
    children: [
      {
        path: '',
        name: 'error',
        component: () => import('../views/Error.vue')
      }
    ]
  },

  // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
]

const router = new VueRouter({
  routes
})

// router.beforeEach((to, from, next) => {
//   console.log(`beforeEach: from ${from.name} to ${to.name}`)
//   console.log('beforeEach: User is', store.state.user)

//   next()
// })

export default router