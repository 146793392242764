import { i18n } from '../services/i18n-setup'

const notifierPlugin = {
  install (Vue, { store }) {
    if (!store) {
      throw new Error('Please provide a Vuex store.');
    }

    Vue.prototype.$notifier = {
      showMessage: function ({ message = '', icon = '', color = '', url = '' }) {
        store.commit('notifier/showMessage', { message, icon, color, url }, { root: true });
      },

      /**
       * Show a default error message. Can be overridden to display specific messages.
       */
      showError: function ({
        message = i18n.t('errors.somethingHappened'),
        icon = 'mdi-alert-circle-outline',
        color = 'error',
        timeout
      }) {
        this.showMessage({ message, icon, color, timeout });
      },

      /**
       * Show a success message. Can be overridden to display specific message.
       */
      showSuccess: function ({
        message = '',
        icon = 'mdi-check',
        color = 'success',
        url,
        timeout
      }) {
        this.showMessage({ message, icon, color, url, timeout });
      }
    }
  }
}

export default notifierPlugin;