import msalConfig from './AuthServiceConfig';
import * as Msal from '@azure/msal-browser';
import * as moment from 'moment';
import axios from 'axios'
import router from '@/router';
import vueInstance from '../main';

export default class AuthenticationService {
  constructor(store) {
    this.app = new Msal.PublicClientApplication(msalConfig)
    this.tokenResponse = null;
    this.accessToken = null;
    this.refreshInterval = null;
    this.api = axios.create({
      baseURL: msalConfig.resources.api.resourceUri
    });

    if (store.state.primaryCustomerId) {
      this.setCustomerId(store.state.primaryCustomerId)
    }

    // this.api.interceptors.request.use(config => this.handleRequest(config), error => error);

    this.api.interceptors.response.use(response => response, error => this.handleError(error));

    this.api.handleErrorsLocally = false;
  }

  init = async (forceLogin = false) => {
    try {
      this.tokenResponse = await this.app.handleRedirectPromise();
      this.accountObj = this.tokenResponse ? this.tokenResponse.account : this.app.getAllAccounts()[0];

      /**
       * Init can be called with a forceLogin argument. If true, skip straight to login
       */
      if (forceLogin) {
        const loginRequest = {
          scopes: msalConfig.resources.api.resourceScope,
          prompt: "select_account",
        }

        await this.app.loginRedirect(loginRequest);
      }

      /**
       * If user has both account and valid token, start a silent refresh for the session
       */
      else if (this.accountObj && this.tokenResponse) {
        this.accessToken = this.tokenResponse.accessToken;
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
        this.setupSilentRefreshAccessToken();
      }

      /**
       * User is logged in but did not have valid tokens.
       * This tries to aquire a new token silently, without requiring user interaction.
       * If the silent renew fails, ask the user to login
       */
      else if (this.accountObj) {
        try {
          await this.refreshTokenSilently();
          this.setupSilentRefreshAccessToken();
        } catch (err) {
          console.error('Failed to aquire token silently', err);
          await this.app.acquireTokenRedirect({ scopes: msalConfig.resources.api.resourceScope });
        }
      }

      /**
       * If no account is found, let the user log in
       */
      else {
        await this.app.loginRedirect({ scopes: msalConfig.resources.api.resourceScope })
      }
    }
    catch (error) {
      //todo: logge dette, eller sende det til brugeren på en måde.
      //console.error("[AuthService.init] Failed to handleRedirectPromise()", error)
      //console.log(error);

      if (error.toString().startsWith('ServerError: invalid_client')) {
        //console.error(error);
        document.location.href = '/#/error?code=accessdenied_invalidclient';
      }
      else if (error.toString().startsWith('ServerError: invalid_resource')) {
        document.location.href = '/#/error?code=accessdenied_invalidresource';
      }
      else if (error.toString().startsWith('ClientAuthError: state_mismatch')) {
        document.location.href = '/#/error?code=clientautherror_statemismatch';
      }
      else {
        console.error(error);
      }

      throw (error);
    }
  }

  /**
   * Refreshes the access token with a set interval. The interval is currently the MSAL default.
   */
  setupSilentRefreshAccessToken = () => {
    this.refreshInterval = setInterval(this.refreshTokenSilently, msalConfig.cache.accessTokenRefreshIntervalSeconds * 1000);
  }

  /**
   * Tries to aquire a new access token without user interaction.
   * If successful, it sets the access token and updates the Authorization header attached to each request.
   */
  refreshTokenSilently = async () => {
    if (!this.accountObj) {
      // Clear the interval
      this.refreshInterval.clearInterval();

      throw 'No account object found!';
    }

    this.tokenResponse = await this.app.acquireTokenSilent({
      account: this.accountObj,
      scopes: msalConfig.resources.api.resourceScope
    });

    this.accessToken = this.tokenResponse.accessToken;
    this.api.defaults.headers.common['Authorization'] = 'Bearer ' + this.accessToken;
  }

  logoff = async () => {
    const logoutRequest = {
      account: this.accountObj
    }

    this.app.logout(logoutRequest);
  }

  getCurrentRoute = () => {
    let routeString = router.match(location).hash;
    routeString = String(routeString).replace('#', '');

    return routeString;
  }

  setCustomerId = (id) => {
    this.api.defaults.headers.common['Customer-Id'] = id;
    console.log('Set customer ID to', id);
  }

  handleRequest = (config) => {
    /**
     * Check if token is expired
     */
    const now = moment();
    const tokenExpiry = moment(this.tokenResponse.expiresOn);

    if (tokenExpiry.isBefore(now)) {
      console.log('User token has expired');
    }

    return config;
  }

  handleError = (error) => {
    if (!error.response) {
      // network error
      router.replace({ path: '/error', query: { code: 'networkerror' } })
    }

    else {
      const statusCode = error.response.status ?? null;
      const details = error.response.data ?? null;
      const returnUrl = this.getCurrentRoute() ?? '';

      switch (statusCode) {
        case 500:
          vueInstance.Sentry.captureException(error);
          vueInstance.$notifier.showError({});
          break;

        case 404:
          router.replace({ path: '/error', query: { code: 'api404', returnUrl: this.getCurrentRoute() } });
          break;

        case 403:
          router.replace({ path: '/error', query: { ...details, returnUrl } });
          this.loaded = true;
          break;

        case 401:
          // Nothing is done right now
          break;

        default:
          break;
      }
    }

    return Promise.reject(error);
  }
}