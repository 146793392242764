import config from '@/assets/config.json'

const { apiUrl, msal } = config;

export default {
  auth: {
    clientId: msal.clientId,
    authority: 'https://login.microsoftonline.com/common',
    validateAuthority: true,
    redirectUri: msal.redirectUri,
    postLogoutRedirectUri: msal.postLogoutRedirectUri,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    accessTokenRefreshIntervalSeconds: 900
  },
  resources: {
    api: {
      resourceUri: apiUrl,
      resourceScope: [msal.resourceScope]
    }
  }
}