export const state = () => ({
  currentSpecification: {}
})

export const mutations = {
  setCurrentSpecification (state, payload) {
    state.currentSpecification = payload;
  }
}

export const actions = {
  async lock({}, id) {
    try {
      const response = await this._vm.$AuthService.api.patch(`specification/${id}/lock`);

      if (!response.data.success) throw response.data.errors[0] ?? 'Error locking specification';

      this._vm.$notifier.showSuccess({ message: 'Specifikationen blev låst' });

      return response.data.model;
    } catch (error) {
      console.error(error);
      this._vm.$notifier.showDefaultError();
    }
  },

  async unlock({}, id) {
    try {
      const response = await this._vm.$AuthService.api.patch(`specification/${id}/unlock`);

      if (!response.data.success) throw response.data.errors[0] ?? 'Error unlocking specification';

      this._vm.$notifier.showSuccess({ message: 'Specifikationen blev låst op' });
    } catch (error) {
      console.error(error);
      this._vm.$notifier.showError({ message: 'Specifikationen kunne ikke låses op' });
      throw error;
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}