import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatNumber', function (value) {
  if (value || value === 0) {
      return Number(value).toLocaleString('da-DK');
  }
});
Vue.filter('formatNumberFixed2', function (value) {
  if (value || value === 0) {
      return Number(value).toLocaleString('da-DK', {
        minimumFractionDigits: 2,      
        maximumFractionDigits: 2,
     });
  }
});

Vue.filter('formatNumberPct', function (value) {
  if (value || value === 0) {
      return Number(value).toLocaleString('da-DK') + '%';
  }
});

Vue.filter('formatTime', function (value) {
  if (value) {
      return moment(value).format('DD-MM-YYYY HH:mm');
  }
});

Vue.filter('formatDateTime', function (value) {
  if (value) {
      return moment(value).format('DD-MM-YYYY HH:mm');
  }
});

Vue.filter('formatDate', function (value) {
  if (value) {
      return moment(value).format('DD-MM-YYYY');
  }
});

Vue.filter('formatShortDate', function (value) {
  if (value) {
      return moment(value).format('DD-MM-YY');
  }
});

Vue.filter('formatDate2', function (value) {
  if (value) {
      return moment(value).format('ddd D MMM');
  }
});

Vue.filter('phoneNumber', function (value) {
  if (!value) return;

  let formatted = String(value);

  if (formatted.startsWith('+45')) {
    formatted = formatted.replaceAll(' ', '');

    return formatted.slice(0, 3) + ' ' + formatted.slice(3, 7) + ' ' + formatted.slice(7);
  }

  return value;
});